/* chart */
.shape {
	fill-opacity: .3;
}

.shape:hover {
	fill-opacity: .65;
	z-index: 100;
}

/* .scale {
	fill: #FAFAFA;
	stroke: #999;
	stroke-width: .2
} */

.axis {
	/* stroke: #555;
	stroke-width: .2 */
}

.dot {
  /* fill: white;
  stroke: #e7e8e7; */
  /* r: 5; */
  /* transition: r .2s; */
}

.dot:hover {
  /* stroke: #bbb;
  r: 8; */
}

.caption {
	/* fill: #444;
	font-weight: 400;
	text-shadow: 1px 1px 0 #fff */
}
